@use "sass:color";

html, body
{
	color: $printBaseColor;
	font-family: Helvetica, Arial, Verdana, sans-serif;
	font-size: rem($fontSize);
	font-weight: 400;
	line-height: rem($fontHeight);
	margin: 0;
	padding: 0;
	text-align: left;
}

*,
*::before,
*::after {
	background: #fff !important;
	color: $printBaseColor !important;
	box-shadow: none !important;
	text-shadow: none !important;
}

fieldset
{
	border: 0;
	margin: 0;
	padding: 0;
}

a
{
	color: $printBaseColor;
	text-decoration: none;
}

b, strong
{
	font-weight: bold;
}

h1, h2, h3, h4, h5, h6
{
	color: $printBaseColor;
	font-size: rem($fontSize);
	font-weight: 700;
	line-height: rem($fontHeight);
	margin: 0;
	padding: 0 0 10px 0;
}

h1
{
	font-size: rem($fontSize + 6);
	line-height: rem($fontHeight + 6);
}

h2
{
	font-size: rem($fontSize + 4);
	line-height: rem($fontHeight + 4);
}

h3
{
	font-size: rem($fontSize + 4);
	line-height: rem($fontHeight + 4);
}

h4
{
	font-size: rem($fontSize + 3);
	line-height: rem($fontHeight + 3);
}

h5
{
	font-size: rem($fontSize + 2);
	line-height: rem($fontHeight + 2);
}

h6
{
	font-size: rem($fontSize + 1);
	line-height: rem($fontHeight + 1);
}

p
{
	margin: 0;
	padding: 0 0 15px 0;
}

ul, ol
{
	margin: 0;
	padding: 0 0 10px 25px;

	li
	{
		margin: 0;
		padding: 0 0 5px 0;
	}
}

dl
{
	margin: 0;
	padding: 0 0 20px 0;

	dt
	{
		color: $printAltColor;
		display: block;
		float: left;
		font-weight: normal;
		margin: 0;
		padding: 0 10px 0 0;
	}

	dd
	{
		display: block;
		margin: 0;
		padding: 0 0 5px 0;
	}
}

img
{
	display: block;
	margin: 0 auto 15px auto;
	padding: 0;
	max-width: 100%;
}

table
{
	border-collapse: collapse;
	margin: 0 0 20px 0;
	padding: 0;
	width: 100%;

	tr
	{
		th, td
		{
			border-bottom: 1px solid $printDividerColor;
			font-size: rem($fontSize - 1);
			font-weight: normal;
			line-height: rem($fontHeight - 1);
			margin: 0;
			padding: 10px 5px;

			h1, h2, h3, h4, h5, h6, p
			{
				font-size: rem($fontSize - 1);
				line-height: rem($fontHeight - 1);
				margin: 0;
				padding: 0;
			}
		}
	}

	thead
	{
		tr
		{
			th, td
			{
				color: $printAltColor;
				font-size: $fontSize - 3;
				line-height: $fontHeight - 3;
				margin: 0;
				padding: 0 5px 5px 5px;
				text-transform: uppercase;
			}
		}
	}
}

small
{
	color: $printAltColor;
	font-size: 80%;
	font-weight: normal;
}


// PRINT
.u-hide-print,
.noPrint,
.bx-controls,
.ui-datepicker,
#nrlskswidget,
.needsclick,
#chat-widget-container
{
	display: none !important;
}


// LAYOUT

// content
.lContent
{
	margin: 0;
	padding: 20px 0;

	&Title
	{
		font-size: rem($fontSize);
		margin: 0 0 15px 0;
		padding: 0;

		.primary
		{
			font-size: rem($fontSize + 6);
			font-weight: 700;
			line-height: rem($fontHeight + 6);
			margin: 0;
			padding: 0;
		}

		.secondary
		{
			font-size: rem($fontSize + 4);
			line-height: rem($fontHeight + 4);
			margin: 0;
			padding: 0;
		}
	}
}

// prefooter
.lPreFooter
{
	border-top: 1px solid $printDividerColor;
	margin: 15px 0 0 0;
	padding: 15px 0 0 0;

	> .inner
	{
		.title
		{
			font-size: rem($fontSize);
			font-weight: 700;
			line-height: rem($fontHeight);
		}

		> .brands
		{
			border-bottom: 1px solid $printDividerColor;
			margin: 0 0 15px 0;
			padding: 0 0 15px 0;

			ul
			{
				list-style: none;
				margin: 0;
				padding: 0;

				li
				{
					float: left;
					margin: 0;
					padding: 0 20px 5px 0;
				}
			}
		}

		> .contact
		{
			margin: 0;
			padding: 0 0 15px 0;

			.phone,
			.email,
			.address
			{
				font-style: normal;
				margin: 0;
				padding: 0;
			}
		}
	}
}

// Footer
.lFooter
{
	border-top: 1px solid $printBaseColor;
	margin: 15px 0 0 0;
	padding: 15px 0 0 0;

	> .inner
	{
		> .copyright
		{
			float: left;
			font-size: rem($fontSize - 1);
			line-height: rem($fontHeight - 1);
			margin: 0;
			padding: 0;
		}

		> .gibe
		{
			color: $printAltColor;
			float: right;
			font-size: rem($fontSize - 1);
			line-height: rem($fontHeight - 1);
			margin: 0;
			padding: 0;

			a
			{
				color: $printAltColor;
			}

			&:after
			{
				content: " - www.gibedigital.com";
			}
		}
	}
}
// footer
.c-footer
{
	border-top: 1px solid $printBaseColor;
	margin: 15px 0 0 0;
	padding: 15px 0 0 0;
}

.c-footer__inline-list
{
	list-style: none;
	margin: 0;
	padding: 0;
}

.c-footer__link--highlight:after
{
	content: " - www.gibedigital.com";
}
// MODULES
// banner
.mBanners
{
	border-bottom: 1px solid $printDividerColor;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
}

.mBanner
{
	background: none !important;
	margin: 0;
	padding: 0;
	position: static !important;
	width: auto !important;

	&Inner
	{
		height: auto !important;
		margin: 0;
		padding: 0;
	}
}
// block
.mBlock
{
	border: 1px solid $printBorderColor;
	margin: 0 0 20px 0;
	padding: 15px 15px 10px 15px;

	&Title
	{
		border-bottom: 1px solid $printBorderColor;
		font-size: rem($fontSize + 2);
		line-height: rem($fontHeight + 2);
		margin: 0 0 15px 0;
		padding: 0 0 10px 0;
	}

	&Inner
	{
		margin: 0;
		padding: 0;
	}
}
// usps
.mUniqueSellingPoints
{
	margin: 0;
	padding: 0 0 5px 0;

	> .inner
	{
		margin: 0;
		padding: 0;

		> .list
		{
			list-style: none;
			margin: 0;
			padding: 5px 0 0 0;

			> .level1
			{
				border-top: 1px solid $printDividerColor;
				list-style: none;
				margin: 0;
				padding: 10px 0;

				&:first-child
				{
					border-top: 0;
					margin-top: 0;
					padding-top: 0;
				}
			}
		}
	}
}
// need some help
.mNeedSomeHelp
{
	border-top: 1px solid $printBorderColor;
	margin: 0;
	padding: 20px 0;

	&Title
	{
		color: $printAltColor;
		font-size: rem($fontSize + 2);
		font-weight: normal;
		line-height: rem($fontHeight + 2);
		margin: 0;
		padding: 0 0 10px 0;
	}

	&Inner
	{
		margin: 0;
		padding: 0;

		.phone
		{
			font-size: rem($fontSize + 2);
			font-weight: bold;
			line-height: rem($fontHeight + 2);
			margin: -10px 0;
		}
	}
}
// blog
.mBlog
{
	&Listing
	{
		border-top: 1px solid $printDividerColor;
		margin: 0;
		padding: 15px 0;

		&Image
		{
			float: left;
			margin: 0 15px 0 0;
			padding: 0;
			width: 150px;

			.image
			{
				margin: 0;
				padding: 0;
			}
		}

		&Content
		{
			margin: 0;
			overflow: hidden;
			padding: 0;
		}

		&Meta
		{
			color: $printAltColor;
			font-size: rem($fontSize - 1);
			line-height: rem($fontHeight - 1);
			margin: 0;
			padding: 5px 0 0 0;

			a
			{
				color: $printAltColor;
			}

			.author
			{
				float: left;
				margin: 0;
				padding: 5px 15px 0 0;
			}

			.date
			{
				float: left;
				margin: 0;
				padding: 5px;
			}
		}

		&:first-child
		{
			border-top: 0;
			padding-top: 0;
		}
	}

	&Post
	{
		overflow: hidden;

		&Title
		{
			margin: 0;
			padding: 0 0 5px 0;
		}

		&Meta
		{
			margin: 0;
			overflow: hidden;
			padding: 0 0 15px 0;

			.author, .date
			{
				color: $printAltColor;
				float: left;
				font-size: rem($fontSize - 1);
				line-height: rem($fontHeight - 1);
				margin: 0;
				padding: 0 10px 0 0;

				a
				{
					color: $printAltColor;
				}
			}
		}

		&Image
		{
			border: 1px solid $printBorderColor;
			float: right;
			margin: 0 0 10px 15px;
			padding: 10px;
			max-width: 45%;

			img
			{
				margin: 0;
			}
		}
	}
}
// comments
.mComments
{
	margin: 0;
	padding: 0;

	&List
	{
		margin: 0;
		padding: 0;

		.list
		{
			list-style: none;
			margin: 0;
			padding: 0;

			.level1
			{
				border-top: 1px solid $printDividerColor;
				list-style: none;
				margin: 0;
				overflow: hidden;
				padding: 15px 0;

				.image
				{
					border: 1px solid $printBorderColor;
					float: left;
					margin: 0 15px 0 0;
					padding: 5px;

					.inner
					{
						margin: 0;
					}
				}

				.comment
				{
					margin: 0;
					overflow: hidden;
					padding: 0;

					.name
					{
						font-size: rem($fontSize);
						line-height: rem($fontHeight);
						margin: 0;
						padding: 0;
					}

					.quote
					{
						margin: 0;
						padding: 0 0 5px 0;
					}

					.date
					{
						color: $printAltColor;
						font-size: rem($fontSize - 2);
						line-height: rem($fontHeight - 2);
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}
}
// basket
.mBasket
{
	margin: 0;
	padding: 0 0 20px 0;

	&Inner
	{
		table
		{
			margin: 0;

			tr
			{
				th, td
				{
					&.price
					{
						width: 80px;
					}

					&.quantity
					{
						text-align: center;
						width: 60px;
					}

					&.total
					{
						text-align: right;
						width: 80px;
					}
				}
			}
		}
	}

	&Addresses
	{
		margin: 0;
		padding: 0;

		&Delivery, &Billing
		{
			float: left;
			width: 25%;
		}

		&Payment
		{
			float: right;
			width: 50%;
		}

		.title
		{
			color: $printAltColor;
			font-size: rem($fontSize);
			font-weight: normal;
			line-height: rem($fontHeight);
			margin: 0;
			padding: 0 0 10px 0;
		}
	}

	&Total
	{
		table
		{
			float: right;
			width: auto;

			tr
			{
				th
				{
					width: 150px;
				}

				td
				{
					text-align: right;
					width: 80px;
				}

				&.total
				{
					th, td
					{
						font-weight: bold;
					}
				}
			}
		}
	}
}
.mBasketInner table tbody tr.header th {
		padding-left: 0;
}
// header
.mHeader {
	border: 1px solid $printBorderColor;
	margin: 0 0 15px 0;
	padding: 15px 15px 5px 15px;

	&Inner {
		.title {
			font-size: rem($fontSize + 2);
			line-height: rem($fontHeight + 2);
			margin: 0;
			padding: 0 0 10px 0;

			.image {
				margin: 0;
			}
		}
	}
}
// full width block
.mFullWidthBlock
{
	border-bottom: 1px solid $printDividerColor;
	margin: 0 0 20px 0;
	padding: 0 0 20px 0;

	&Title
	{
		padding-bottom: 20px;
	}
}

.m-categories
{
	margin: 0;
	padding: 0;

	&__menu
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -15px 0 0 -15px;
		padding: 0;

		&__item
		{
			@include box-sizing();
			display: inline-block;
			font-size: rem($fontSize);
			line-height: rem($fontHeight);
			list-style: none;
			margin: 0;
			padding: 15px 0 0 15px;
			vertical-align: top;
			width: 25%;
		}

		&__image
		{
			display: block;
			height: auto;
			margin: 0;
			padding: 0;
			width: 100%;
		}

		&__title
		{
			display: block;
			font-weight: bold;
			margin: 0;
			padding: 10px 0;
		}
	}
}
// features
.m-features
{
	margin: 0;
	padding: 0;

	&__menu
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: -15px 0 0 -15px;
		padding: 0;

		&__item
		{
			@include box-sizing();
			display: inline-block;
			font-size: rem($fontSize);
			line-height: rem($fontHeight);
			list-style: none;
			margin: 0;
			padding: 15px 0 0 15px;
			vertical-align: top;
			width: 25%;
		}

		&__image
		{
			display: block;
			height: auto;
			margin: 0;
			padding: 0;
			width: 100%;
		}

		&__title
		{
			display: block;
			font-weight: bold;
			margin: 0;
			padding: 10px 0;
		}
	}
}
// range
.mRange
{
	&Top
	{
		margin: 0;
		padding: 0 0 15px 0;
	}

	&Item
	{
		border-bottom: 1px solid $printDividerColor;
		@include min-height(110px);
		margin: 0 0 15px 0;
		padding: 0 0 15px 125px;
		position: relative;
		z-index: 1;

		&Image
		{
			background: #fff;
			border: 1px solid $printBorderColor;
			left: 0;
			margin: 0;
			padding: 2px;
			position: absolute;
			top: 0;
			width: 100px;
			z-index: 1;

			.image
			{
				margin: 0;
				padding: 0;
			}
		}

		&Title
		{
			font-size: rem($fontSize + 2);
			line-height: rem($fontHeight + 2);
			margin: 0;
			padding: 0;
		}

		&Promo
		{
			color: $printAltColor;
			font-style: italic;
			margin: 0;
			padding: 5px 0 0 0;
		}

		&Stock
		{
			color: $printAltColor;
			margin: 0;
			padding: 5px 0 0 0;

			&:before
			{
				content: "✘ ";
				margin: 0;
				padding: 0;
			}

			&.in,
			&.expedited
			{
				color: $printSuccessColor;

				&:before
				{
					content: "✔  ";
				}
			}
		}

		&Price
		{
			margin: 0;
			padding: 5px 0 0 0;

			.rrp
			{
				color: $printAltColor;
				font-size: rem($fontSize - 1);
				line-height: rem($fontHeight - 1);
				margin: 0;
				padding: 0;
			}

			.price
			{
				font-weight: bold;
				font-size: rem($fontSize + 4);
				line-height: rem($fontHeight + 4);
				margin: 0;
				padding: 5px 0 0 0;
			}

			.saving
			{
				color: $printColorSecondary;
				font-size: rem($fontSize - 1);
				font-weight: bold;
				line-height: rem($fontHeight - 1);
				margin: 0;
				padding: 0;
			}
		}

		&.feature
		{
			background: color.adjust($printPrimaryColor, $lightness: 57%, $space: hsl);
			border: 2px solid color.adjust($printPrimaryColor, $lightness: 53%, $space: hsl);
			padding-left: 140px;
			padding-right: 15px;
			padding-top: 15px;

			.mRange
			{
				&Item
				{
					&Image
					{
						left: 15px;
						top: 15px;
					}
				}
			}
		}
	}
}

.formMessage {
		padding: 0;
}

/* Order Complete */

.c-order-complete__inner {
		max-width: none;
		padding: 0;
}

.c-order-complete__order-details {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}

.c-order-complete__grid {
	display: block !important;
	margin-top: -20px !important;
}

.c-order-complete .c-pod {
	margin-bottom: 0 !important;
}

.c-order-complete__totals {
	break-inside: avoid;
	page-break-inside: avoid;
}
