﻿@include critical {

	// clearfix
	.clearfix:after, .formWrapper:after, .formRow:after, .formAction:after, .cms:after
	{
		clear: both;
		content: "";
		display: block;
		font-size: 0;
		height: 0;
		visibility: hidden;
	}

	* html .clearfix, * html .formWrapper, * html .formRow, * html .formAction, * html .cms
	{
		height: 1%;
	}
}